import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';

const Container = styled.div`
    margin: 0em 10vw 1em 10vw;

    @media only screen and (max-width: 768px) {
        margin: 0em 1em 1em 1em;
    }
`;

const TagsContainer = styled.div`
    margin-top: 2em;
    text-align: center;
`;

const Tags = styled.div`
    display: flex;
    justify-content: center;
`;

const Title = styled.h2`
    margin-bottom: 1em;
`;

const Description = styled.p`
    margin-bottom: 1em;
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const Tag = styled(props => <Link {...props} />)`
    margin: 1em 0.5em;
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    text-align: center;
    font-weight: 300;
    font-size: 0.8em;
    border: 1px solid black;
    padding: 0.5em;
    cursor: pointer;

    &:hover {
        color: white;
        background-color: black;
        transition: background 0.3s;
    }
`;

export const BlogPostTemplate = ({
    content,
    contentComponent,
    description,
    tags,
    title,
    helmet,
}) => {
    const PostContent = contentComponent || Content;

    return (
        <Container className="section">
            {helmet || ''}
            <Title>{title}</Title>
            <Description>{description}</Description>
            <PostContent content={content} />
            {tags && tags.length ? (
                <TagsContainer>
                    <h4>Tagi:</h4>
                    <Tags>
                        {tags.map(tag => (
                            <Tag
                                key={`${tag}tag`}
                                to={`/tags/${kebabCase(tag)}/`}
                            >
                                {tag}
                            </Tag>
                        ))}
                    </Tags>
                </TagsContainer>
            ) : null}
        </Container>
    );
};

BlogPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    helmet: PropTypes.object,
    tags: PropTypes.object,
    title: PropTypes.string,
};

const BlogPost = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <BlogPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                helmet={() => (
                    <Helmet titleTemplate="%s | Blog">
                        <title>{`${post.frontmatter.title}`}</title>
                        <meta
                            content={`${post.frontmatter.description}`}
                            name="description"
                        />
                    </Helmet>
                )}
                tags={post.frontmatter.tags}
                title={post.frontmatter.title}
            />
        </Layout>
    );
};

BlogPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
};

export default BlogPost;

export const pageQuery = graphql`
    query BlogPostByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
                tags
            }
        }
    }
`;
